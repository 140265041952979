var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"8"}},[_c('app-text-search-field',{attrs:{"label":"Pesquisar"},on:{"filter":function($event){_vm.showFilter = !_vm.showFilter}},model:{value:(_vm.searchParams.text),callback:function ($$v) {_vm.$set(_vm.searchParams, "text", $$v)},expression:"searchParams.text"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.select()}}},[_c('app-icon',[_vm._v("search")])],1)],1)],1),(_vm.showFilter)?_c('v-row',[_c('v-col',[_c('app-select',{attrs:{"items":_vm.positions,"label":"Cargo"},model:{value:(_vm.searchParams.position),callback:function ($$v) {_vm.$set(_vm.searchParams, "position", $$v)},expression:"searchParams.position"}})],1),_c('v-col',[_c('app-text-field',{attrs:{"label":" Data Inicial","type":"date"},model:{value:(_vm.searchParams.start_date),callback:function ($$v) {_vm.$set(_vm.searchParams, "start_date", $$v)},expression:"searchParams.start_date"}})],1),_c('v-col',[_c('app-text-field',{attrs:{"label":" Data Final","type":"date"},model:{value:(_vm.searchParams.end_date),callback:function ($$v) {_vm.$set(_vm.searchParams, "end_date", $$v)},expression:"searchParams.end_date"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.commissions.data,"hide-default-footer":"","disable-sort":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.salesman.name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.created_at))+" ")]}},{key:"item.calc_base",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.moneyBr(item.calc_base))+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.fee))+"% ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.moneyBr(item.value))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.statuses[item.status].color}},[_vm._v(" "+_vm._s(_vm.statuses[item.status].text)+" ")])]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.commissions},on:{"click":function($event){return _vm.select($event)}}})],1),_c('v-col',[_vm._l((_vm.commission_sum),function(commission,index){return [_c('v-card',{key:index,staticClass:"my-2",attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"pt-2"},[_c('app-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("monetization_on ")]),_c('b',[_vm._v(_vm._s(commission.salesman.name))])],1),_c('v-card-subtitle',{staticClass:"d-flex justify-space-between pb-2"},[_c('div',[_c('div',[_c('span',[_vm._v("Comissão :")]),_c('b',[_vm._v(_vm._s(_vm.$format.moneyBr(commission.value)))])]),_c('div',[_c('span',[_vm._v("Base Calculo :")]),_c('b',[_vm._v(_vm._s(_vm.$format.moneyBr(commission.calc_base)))])]),_c('div',[_c('span',[_vm._v("Taxa :")]),_c('b',[_vm._v(_vm._s(_vm.$format.decimal(commission.fee))+" %")])])])])],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }